import { colors } from "@mui/material";

export const projectList = [
  {
    titleimg: "WeatherApp_title.png",
    projectcode: "1",
    title: "Weather App",
    shortdescription: "I created my first Flutter Weather App",
    description: "I developed my first Flutter app, a weather application, using the OpenWeatherAPI. It displays real-time weather data, including the current conditions and a 5-day forecast. Users can also search for weather information in different locations.",
    backgroundColor: "#FFC85E",
  
    bannerimg: "/weatherapp_banner.png",
    problems: ["Current weather based on user's location", "Ability to change the location", "Detailed 5-day weather forecast"],
    solutions: "I developed my first Flutter app, a weather application, using the OpenWeatherAPI. It allowed me to showcase real-time weather data, featuring the current conditions and a 5-day forecast. I even incorporated a location search feature to empower users to explore weather information in various locations.\n\nTo create this weather app, I embarked on a journey of exploration and implementation. It all began with extensive research into the OpenWeatherAPI. I dived into the API's documentation, gaining valuable insights into the wealth of data it could provide. After carefully assessing its capabilities, I meticulously handpicked the data I wanted to showcase within my app.\n\nI focused intently on the app's visual aesthetics. I either curated or designed weather icons that perfectly encapsulated various weather conditions, ensuring that the app not only served its informative purpose but also delighted users with its visual appeal. Armed with my chosen data and icons, I set about creating a user experience (UX) design that would make my app exceptionally user-friendly and visually pleasing. This design served as a blueprint for my app's layout, ensuring that the selected data and icons were presented clearly and elegantly.\n\nIn the initial stages, I constructed a static data mockup of my app. This served as a foundational stepping stone for visualizing the layout and user interface, allowing me to identify and resolve any design or layout challenges before the final implementation. The next pivotal step was the integration of the OpenWeatherAPI into my app. I crafted models to efficiently manage API responses for both the current weather and forecasts. Subsequently, I seamlessly replaced the static data with dynamic information fetched in real-time from the API, guaranteeing that my app offered users the most up-to-date and accurate weather information.\n\n",
    screens: ["/weatherapp_screen1.png", "/weatherapp_screen2.png", "/weatherapp_screen3.png"]
  },

]